import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useNavigate ,
  } from "react-router-dom";
import Superuser from './SuperUser/SuperUser';
import Admin from './Admin/Admin'
import { isLoggedIn } from '../../utils/utils';
const Users = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const navigate = useNavigate()
    const { user } = useParams();

    useEffect(() => {
        setIsAuthenticated(isLoggedIn())
      }, []);
    console.log("inside users >>>>>>>>", {user}, )
    console.log({isAuthenticated})
    if (isAuthenticated) {
        if (user === "admin") {
            return (
                <Admin/>
            );  
        }
        else if (user === "superuser") {
            return (
                <Superuser />
            );
        }
    } else {
        navigate("/login")
    }
    
};

export default Users;