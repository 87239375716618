import React from 'react'
import { Box, Typography, Badge, Button, Paper, Divider } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import displayRazorpay, {createOrder, createTransaction, pushDispenseItemDetails} from "../apis/routes";
import {
    useNavigate, useParams
} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function Breakdown({ added_data = {}, vm_id }) {
    console.log(">>>>>>>>>", added_data)
    const navigate = useNavigate()
    const {id} = useParams()

    const getTotalPrice = () => {
        let total_price = 0
        Object.keys(added_data).map((id) => {
            total_price += added_data[id]['price'] + (added_data[id]['tax'] / 100) * added_data[id]['price']
        });
        return total_price.toFixed(2)
    }

    const pay = async() => {
        console.log("pay")
        await displayRazorpay(getTotalPrice()).then((data)=>{
            console.log({data})
            const options = {
                key: "rzp_live_pOvPLImVrK6UIM",
                currency: data.currency,
                amount: data.amount,
                name: "ivm9",
                description: "Wallet Transaction",
                image: "",
                order_id: data.id,
                handler: async function (response) {
                    console.log("response >>>>>>>>,", response)
                    // alert("PAYMENT ID ::" + response.razorpay_payment_id);
                    // alert("ORDER ID :: " + response.razorpay_order_id);
 
                    if(response) {
                        navigate(`/success/${response.razorpay_order_id}`)
                        await createOrder({amount: data.amount, items_list: Object.keys(added_data), vending_machine_id:vm_id, metadata:{}}).then(async(res) => {
                            await createTransaction({order_id: res.guid, status:1, metadata:{payment_id:response.razorpay_payment_id, razorpay_order_id:response.razorpay_order_id}})
                        })
                        await pushDispenseItemDetails({vm_data: vm_id, item_data:Object.values(added_data).map((i) => { return {slot: i.slot, quantity: i.count}})}) 
                    }
                    console.log("ok")
                    navigate(`/vm/${id}`);
                },
            };
        
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            })
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    });

    return (
        <>  <Box sx={{mb: 10}}>
            {/* {Object.keys(added_data).map((id) => (
                <Paper elevation={3} sx={{my:2, margin:"auto", textAlign: "center"}}>
                    <List sx={{ width: '100%',}}>
                        <ListItem sx={{my:1, margin:"auto", textAlign: "center"}}>
                            <Grid container>
                                <Grid item xs={12} md={12} sx={{backgroundColor:'#f3e5f5'}}>
                                   <Typography variant='h6'>{added_data[id]['name']}</Typography>
                                </Grid>
                                <Divider component="li"></Divider>
                                <Grid item xs={12} md={12}>
                                    <Typography alignItems='right'> Count: {added_data[id]['count']}</Typography>
                                </Grid>
                                <Grid item  xs={12} md={12}>
                                    <Typography alignItems='right'> Price: {added_data[id]['price']}</Typography>
                                </Grid>
                                <Grid item x xs={12} md={12}>
                                    <Typography alignItems='right'> Tax: {added_data[id]['tax']}</Typography>
                                </Grid>
                                <Grid item  xs={12} md={12}>
                                    <Typography alignItems='right'> Total Price : {added_data[id]['price'] + (added_data[id]['tax'] / 100) * added_data[id]['price']}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </Paper>
            ))} */}
            <TableContainer component={Paper}>
                <Table sx={{ }} aria-label="caption table">
                    <caption><b>Total Cart Value: <span style={{color:'green', fontSize: '20px', fontWeight:'bold'}}>{getTotalPrice()}</span></b></caption>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            {/* <TableCell align="right"></TableCell> */}
                            <TableCell align="center">Price&nbsp;(Rs.)</TableCell>
                            <TableCell align="center">Tax&nbsp;(%)</TableCell>
                            <TableCell align="center">Total Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(added_data).map((id, i) => (
                            <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {added_data[id]['name']}
                            </TableCell>
                            <TableCell align="center">{added_data[id]['count']}</TableCell>
                            <TableCell align="center">{added_data[id]['price']}</TableCell>
                            <TableCell align="center">{added_data[id]['tax']}%</TableCell>
                            <TableCell align="center">{added_data[id]['price'] + (added_data[id]['tax'] / 100) * added_data[id]['price']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
            <Box
                sx={{
                    textAlign: "center", bgcolor: "#e0e0e0", color: "white", p: 2, position: 'fixed',
                    bottom: 0, width: '100%'
                }}
            >
                {/* <Badge badgeContent={4} color="primary"> */}
                <Typography color='primary'>Rs.{getTotalPrice()}</Typography>
                {/* </Badge> */}
                <Button sx={{ ml: 3, fontWeight: "bold" }} variant="outlined" size="large" color="primary" onClick={pay} >
                    Proceed to pay
                </Button>
            </Box>
        </>
    )
}