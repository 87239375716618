import React, {useState} from "react";
import Layout from "./../components/Layout/Layout";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Typography, Select, MenuItem} from '@mui/material';
import "../styles/HomeStyles.css";
import Image from '../images/login.JPG'

import Cookies from 'js-cookie';
import {
  useNavigate ,
} from "react-router-dom";

import { login_user } from "../apis/routes";

const Login = () => {
  const navigate = useNavigate();
    const [user_type, setUserType] = useState('');
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleChange = (event) => {
      console.log({event});
        setUserType(event.target.value);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      if ((username === "") & (password === "")) {
          return;
      }
      console.log({event});
      const data = new FormData(event.currentTarget);
      console.log(data, {
          email: data.get('id'),
          password: data.get('password'),
          user_type: data.get('user_type'),
      });

      const res = await login_user({
          id: data.get('id'),
          password: data.get('password'),
          user_type: data.get('user_type'),
      });
      console.log({res});
      if (res.status === 200 && res.data.authenticated) {
          // console.log("set cookie")

          // await new Promise((resolve, reject) => {
          //   try {
              
          //     resolve();
          //   } catch (error) {
          //     reject(error);
          //   }
          // });
          Cookies.set("ivm9@user_token", res.data.jwt);
          console.log("+++++LOGIN+++++", Cookies.get('ivm9@user_token'))
          // setTimeout(() => {
              navigate(`/home/${data.get('user_type') == "10" ? "superuser" : "admin"}`);
          // }, 1500);
      } else {
          console.log("login err")
      }
    };
  return (
    <Layout>
      <Box className="home">
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mt:18,
          }}
        >
          <img src={Image} style={{ maxWidth: "100%", height:"400px", width:"400px" }}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={0} square mt={12} >
          <Box
            sx={{
              my: 8,
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="id"
                label="id"
                name="id"
                autoComplete="email"
                autoFocus
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Select 
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user_type}
                label="User Type"
                name="user_type"
                onChange={handleChange}
              >
                  <MenuItem value={20}>Admin</MenuItem>
                  <MenuItem value={10}>Super Admin</MenuItem>
              </Select>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="primary"
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
                <Grid item>
                  {/* <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </Layout>
  );
};

export default Login;