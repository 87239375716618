import React from "react";
import Layout from "./../components/Layout/Layout";
// import { Link } from "react-router-dom";
import Banner from "../images/banner.jpg";
import {Box} from '@mui/material'
import "../styles/HomeStyles.css";

const Home = () => {
  return (
    <Layout>
      <Box className="home" style={{ backgroundImage: `url(${Banner})`, backgroundPosition: "center", height:"100vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  overflow: "scroll" }}>
        {/* <div className="headerContainer">
          <h1>Food Website</h1>
          <p>Best Food In India</p>
          <Link to="/menu">
            <button>ORDER NOW</button>
          </Link>
        </div> */}
      </Box>
    </Layout>
  );
};

export default Home;