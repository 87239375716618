import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import {Typography, Grid, Paper, CardActions, Chip, Divider, Button} from '@mui/material/';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useState, useEffect } from 'react';

import Chips from '../../images/chips.jpg';

export default function MediaControlCard({item = {id: "1", name: "Chips", price: 10, stock: 5, desc: 'The packet is of 50gm', image: Chips, tax: 1, slot: 1, selected_count: 0}, callback}) {
    const [count, setCount] = useState(item.selected_count);
    const [loading, setLoading] = useState(true);

    const updateCountValue = (value, action) => {
        setCount(value);
        callback(action, {id: item.id, selectedCount: value, totalPrice: value * item.price, name: item.name, tax: item.tax, slot: item.slot});
    };

    useEffect(() => {
        setLoading(true);
        setCount(item.selected_count);
        setLoading(false);
    }, [item.selected_count]);

    return (
        !loading ? (
            <Box>
                <Grid container component="main" sx={{ mt: 2 }} elevation={2}>
                    <Grid item xs={8} sm={8} md={8} component={Paper} elevation={0} square>
                        <Card sx={{ textAlign: 'left', ml: 2 }} elevation={0}>
                            <Typography color='primary' variant='h5'>
                                {item.name}
                            </Typography>
                            <Typography color='grey' variant='h6'>
                                {item.desc}
                            </Typography>
                            <Chip sx={{ fontSize: '20px', my: 1 }} icon={<CurrencyRupeeIcon size="small" />} color="success" label={item.price} />
                            <Typography>Total for the item Rs.{count * item.price}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Card elevation={0} sx={{ p: 1, alignContent: 'center', textAlign: 'center' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 80, margin: 'auto' }}
                                image={`../../../../assets/${item.image}`}
                            />
                            <CardActions sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                                {count === 0 ? (
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => updateCountValue(1, 'add')}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton aria-label="remove" onClick={() => count > 0 && updateCountValue(count - 1, 'remove')}>
                                            <RemoveIcon color="primary" />
                                        </IconButton>
                                        <Typography variant='h5'>{count}</Typography>
                                        <IconButton aria-label="add" onClick={() => count < item.stock && updateCountValue(count + 1, 'add')}>
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    </Box>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Divider />
            </Box>
        ) : ''
    );
}
