import React from "react";
import Layout from "./../components/Layout/Layout";
import {
  Box,
  Typography,
} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../styles/HomeStyles.css";

const Condition = () => {
  return (
    <Layout>
      <Box className="home" width="100%" sx={{ textAlign: "center", my:5}}>
        <Typography variant="h4">ivm9 Terms and Conditions</Typography>
        <Box sx={{ textAlign: "center", my: 5, mx: 5, "& h4": { fontWeight: "bold", mb: 2 } }}>
          <Typography>
            Last updated on Dec 26, 2023.
            For the purpose of these Terms and Conditions, the terms "we," "us," and "our" refer to UDVABAN TECHNOLOGIES PRIVATE LIMITED. The terms "you," "your," "user," and "visitor" shall mean any natural or legal person visiting our website and/or agreeing to purchase from us.
          </Typography>
          <Typography variant="h6" sx={{fontWeight:"bold", mt:3}}>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</Typography>
          <Box
            alignItems="center"
            sx={{
                display:"flex",
                textAlign: "center",
                m: 3,
                mx: 15,
                mb:20,
                "@media (max-width:600px)": {
                mx: 1,
                mb:15,
                },
                justifyContent: 'center'
            }}
          >
            <TableContainer component={Paper} elevation={5}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">The content of the pages of this website is subject to change without notice.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.
                      You may not create a link to our website from another website or document without UDVABAN TECHNOLOGIES PRIVATE LIMITED’s prior written consent.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Condition;