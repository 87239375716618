import React from "react"

import {Badge, Box, Button, Snackbar} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Cart({count = 5, id=1, callback}) {
    const [open, setOpen] = React.useState(false);

    const handleClick = (status) => {
        setOpen(status);
      };
    
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    };
    // const navigate = useNavigate();
    console.log("badge count >>>>>>>>>>>>>>>", count, id,)

    const checkout = () => {
        // navigate(`/vm/${id}/checkout`)
        console.log("checkout")
        // displayRazorpay(10)
        if(count<1){
            handleClick(true)
            return;
        }
        callback(true)
    }
    const action = (
        <React.Fragment>
          {/* <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button> */}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    return (
        <Box
            sx={{ textAlign: "center", bgcolor: "#e0e0e0", color: "white", p: 2, position: 'fixed',
                bottom: 0, width: '100%' }}
        >
            <Badge badgeContent={count} color="primary">
                <ShoppingCartIcon size="large" color="primary" />
            </Badge>
            <Button sx={{ml:1, fontWeight: "bold"}} variant="outlined" size="large" color="primary" onClick={checkout} >
                Checkout
            </Button>
            <Snackbar
                anchorOrigin={{ vertical:'top', horizontal :'center'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Add items to cart to checkout"
                action={action}
            />
        </Box>
    )
}