// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-menu li {
  margin: 0px 20px;
}
.navigation-menu li a {
  text-decoration: none;
  color: white;
}
.active {
  color: black !important;
  font-weight: bold !important;
  font-size: 18px
}
/* mobile view */
.mobile-navigation {
  list-style: none;
}
.mobile-navigation li {
  margin-top: 20px;
}
.mobile-navigation li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/styles/HeaderStyles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B;AACF;AACA,gBAAgB;AAChB;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".navigation-menu {\n  list-style: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.navigation-menu li {\n  margin: 0px 20px;\n}\n.navigation-menu li a {\n  text-decoration: none;\n  color: white;\n}\n.active {\n  color: black !important;\n  font-weight: bold !important;\n  font-size: 18px\n}\n/* mobile view */\n.mobile-navigation {\n  list-style: none;\n}\n.mobile-navigation li {\n  margin-top: 20px;\n}\n.mobile-navigation li a {\n  text-decoration: none;\n  font-size: 1.4rem;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
