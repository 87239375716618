import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { createItem, getAllItems, updateItem, uploadImageFile, deleteItem } from '../../../apis/routes'
import { getUserDetails } from '../../../utils/utils';

const VendingMachineItems = () => {
  const curr_user = getUserDetails();
  console.log({ curr_user });
  const user_id = curr_user.guid

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [itemData, setItemData] = useState(() => []);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const updateItemResponseData = (data) =>{
    data.forEach((item, index) => {
      data[index]["created_at"] = (new Date(item.created_at)).toLocaleString();
      data[index]["updated_at"] = (new Date(item.updated_at)).toLocaleString();
      console.log("+++>>>>>", item.metadata)
      data[index]["image"] = item.metadata?.["image"] ? item.metadata["image"] : '';
      data[index]["user_id"] = item.metadata?.["user_id"] ? item.metadata["user_id"] : '';
      data[index]["metadata"] = item.metadata?.["description"];
      
    })
    
    data = data.filter(i => {
      return i.user_id && i.user_id === user_id
    })
    return data;
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const items = await getAllItems();

      setItemData(updateItemResponseData(items));
      // const response = await getAllVM();
      // console.log({vms: response});
      // setTableData(updateVMResponseData(response, users));
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // console.log({admins});
  }, []);

  const handleCreateNewRow = async (values) => {
    // tableData.push(values);
    // setTableData([...tableData]);
    console.log("handleCreateNewRow", values);
    delete values["guid"];
    delete values["created_at"];
    delete values["updated_at"];
    values["item_price"] = parseInt(values["item_price"], 10);
    values["tax"] = parseFloat(values["tax"]);
    values["metadata"] = {"description": values["metadata"], "image": values["image"], "user_id": user_id};
    delete values["image"];
    await createItem(values);
    await fetchData();
  };

  const uploadImage = async(e) => {
    console.log(">>>UPLOADIMAGE>>>>", e.target.files)
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    await uploadImageFile(formData)
    
  }

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      itemData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      console.log("handleSaveRowEdits -----", values);
      setItemData([...itemData]);
      await updateItem(values.guid, {
        "item_name": values.item_name,
        "item_price": values.item_price,
        "tax": values.tax,
        "metadata": {"description": values.metadata},
      });
      fetchData();
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ${row.getValue('item_name')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      itemData.splice(row.index, 1);
      setItemData([...itemData]);
      await deleteItem(row.original.guid)
    },
    [itemData],
  );

  // const getCommonEditTextFieldProps = useCallback(
  //   (cell) => {
  //     return {
  //       error: !!validationErrors[cell.id],
  //       helperText: validationErrors[cell.id],
  //       onBlur: (event) => {
  //         const isValid =
  //           cell.column.id === 'email'
  //             ? validateEmail(event.target.value)
  //             : cell.column.id === 'age'
  //             ? validateAge(+event.target.value)
  //             : validateRequired(event.target.value);
  //         if (!isValid) {
  //           //set validation error for cell if invalid
  //           setValidationErrors({
  //             ...validationErrors,
  //             [cell.id]: `${cell.column.columnDef.header} is required`,
  //           });
  //         } else {
  //           //remove validation error for cell if valid
  //           delete validationErrors[cell.id];
  //           setValidationErrors({
  //             ...validationErrors,
  //           });
  //         }
  //       },
  //     };
  //   },
  //   [validationErrors],
  // );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = cell.column.id === 'item_price' ? isNaN(event.target.value) : true
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `Price should be number`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'guid',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'item_name',
        header: 'Item Name',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'item_price',
        header: 'Price',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'tax',
        header: 'Tax %',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'metadata',
        header: 'Item Description',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
          accessorKey: 'image',
          header: 'Image',
          size: 140,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          Cell: ({ row }) => (
            // console.log("++++++",{row},)
            <Box sx={{ display: 'flex', gap: '2ch', alignItems: 'center', }}>
              <img alt='' src={`../../../../assets/${row.original.image}`} style={{width: "80px", height:"80px"}} />
              {/* <a href={row.profileUrl}>{row.name}</a> */}
            </Box>
          ),
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
      {
        accessorKey: 'updated_at',
        header: 'Updated At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
    ],
    // [getCommonEditTextFieldProps],
  );

  return (
    <>
      {!loading && <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={itemData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Item
          </Button>
        )}
      />}
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        VisuallyHiddenInput={VisuallyHiddenInput}
        uploadImage={uploadImage}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, VisuallyHiddenInput, uploadImage }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Item</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              (column.accessorKey === "item_name" || column.accessorKey === "metadata" || column.accessorKey === "item_price" || column.accessorKey === "tax") ? <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              /> : (column.accessorKey === "image" && <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
                <VisuallyHiddenInput  
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>{
                  setValues({ ...values, [e.target.name]: e.target.files[0].name });
                  uploadImage(e);
                }
                }
                type="file" />
              </Button> )
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Create New Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default VendingMachineItems;
