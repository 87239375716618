import React from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import Layout from './../components/Layout/Layout';
import logo from '../images/1.png'; // Logo image
import product1 from '../images/2.png'; // First product image
import product2 from '../images/3.png'; // Second product image
import product3 from '../images/4.png'; // Second product image
const Product = () => {
    return (
        <Layout>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, backgroundColor: '#f0f4ff' }}>
                {/* Left Side: Logo and Purchase Button */}
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ flex: '0 0 auto', textAlign: 'center', mr: 3 }}>
                            {/* Logo */}
                            <img src={logo} alt="ivm9 Product Prices" style={{ maxWidth: '400px', margin: '20px 0' }} />

                            {/* Purchase Button */}
                            <Box sx={{ mt: 2 }}>
                                <Typography variant='h6'>
                                    Our vending machines come in two sizes, one with 16item loading capacity and other with 32 item loading capacity. We have another refreshing product, a tea and coffee vending machine.
                                    <br /><br/>
                                    Please reach contact us for more interesting offers and bulk deals.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="https://forms.gle/o6F2sUwoSzg4zKTVA"
                                    sx={{
                                        mt:3,
                                        mb:3,
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        backgroundColor: '#007bff',
                                        '&:hover': {
                                            backgroundColor: '#0056b3',
                                        },
                                    }}
                                    size='large'
                                >
                                    Click to Purchase
                                </Button>
                                <Typography >
                                    * Physical specifications and price may vary as per stock and model availability.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Right Side: Product Images in a Column */}
                    {/* <Box sx={{ flex: '1 1 auto' }}> */}
                        <Grid item xs={12} sm={12} md={6}>
                            {/* Product 1 */}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        p: 2,
                                        textAlign: 'center',
                                    }}
                                >
                                    <img src={product1} alt="Product 1" style={{ width: '100%', borderRadius: '8px' }} />
                                </Box>
                            </Grid>

                            {/* Product 2 */}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        p: 2,
                                        textAlign: 'center',
                                    }}
                                >
                                    <img src={product2} alt="Product 2" style={{ width: '100%', borderRadius: '8px' }} />
                                </Box>
                            </Grid>
                            {/* Product 3 */}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        p: 2,
                                        textAlign: 'center',
                                    }}
                                >
                                    <img src={product3} alt="Product 3" style={{ width: '100%', borderRadius: '8px' }} />
                                </Box>
                            </Grid>
                        </Grid>
                    {/* </Box> */}
                </Grid>
            </Box>
        </Layout>
    );
};

export default Product;
