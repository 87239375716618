import React from 'react'
import {Box, Typography} from '@mui/material'
import { useParams } from 'react-router-dom'
export default function Success() {
    const {order_id} = useParams()
    return (
        <Box sx={{backgroundColor: 'green', color:'white', width: '100vw', height:'800px',margin:'auto', textAlign:'center', overflowY: "hidden"}}>
            <Typography sx={{pt:10, mb:2,}} variant="h4" color='white'>Success</Typography>
            <Typography sx={{}} variant='h6' color='white'>Order Id: {order_id}</Typography>
            <Typography sx={{}} variant='h6' color='white'>Your order is processing please wait for some time</Typography>
            <Typography sx={{mt: 10}} variant='h6' color='white'>Redirecting ....</Typography>
        </Box>
    )
}