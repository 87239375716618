import React from "react";
import Layout from "./../components/Layout/Layout";
import {
  Box,
  Typography

} from '@mui/material'
import "../styles/HomeStyles.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Cancel = () => {
  return (
    <Layout>
      <Box className="home" width="100%" sx={{ textAlign: "center", my:5}}>
        <Typography variant="h4">Cancellation Policy</Typography>
        <Box sx={{ textAlign: "center", my: 5, mx: 5, "& h4": { fontWeight: "bold", mb: 2 } }}>
          <Typography>Last updated on Dec 26, 2023.</Typography>
          <Typography variant="h6" sx={{fontWeight:"bold", mt:3}}>Order Cancellations:</Typography>
          <Typography>UDVABAN TECHNOLOGIES PRIVATE LIMITED believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</Typography>
          <Box
            alignItems="center"
            sx={{
                display:"flex",
                textAlign: "center",
                m: 3,
                mx: 15,
                mb:20,
                "@media (max-width:600px)": {
                mx: 1,
                mb:15,
                },
                justifyContent: 'center'
            }}
          >
            <TableContainer component={Paper} elevation={5}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">The content of the pages of this website is subject to change without notice.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">UDVABAN TECHNOLOGIES PRIVATE LIMITED does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">In case of any Refunds approved by the UDVABAN TECHNOLOGIES PRIVATE LIMITED, it’ll take 6-8 days for the refund to be processed to the end customer.</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Cancel;