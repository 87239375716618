import axios from 'axios';
// import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';

// const history = createBrowserHistory();
// import { apiUrl } from '../config';

//const apiUrl = "http://213.210.37.164:8000/"
export const register_user = async (formData) => {
    try {
        const res = await axios.get(`/user/create/`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(formData),
        });
        const data = res.json();
        return data;
    } catch (error) {
        console.log('Error in register_user (service) => ', error);
        return error.message
    }
};


export const login_user = async (formData) => {
    try {
        return axios.post(`/login/`, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            console.log("+++++++++", res);
            return res;
        }).catch(error => {
            console.log(">>>>>>>>>>>>", error);
            return error
        });
    } catch (error) {
        console.log('Error in login_user', error);
        return error.message
    }
};

export const getAllVM = async () => {
    return await axios.get(`/vm/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllUsers = async () => {
    return await axios.get(`/user/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllItems = async () => {
    return await axios.get(`/item/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllSlotsItems = async () => {
    return await axios.get(`/item/slots/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateVM = async (id, data) => {
    console.log("updateVM----", data);
    return await axios.put(`/vm/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateUser = async (id, data) => {
    return await axios.put(`/user/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateItem = async (id, data) => {
    console.log("updateItem >>>>", data);
    return await axios.put(`/item/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createVM = async (data) => {
    console.log("createVM----------",data);
    return await axios.post(`/vm/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}


export const createUser = async (data) => {
    console.log("createUser----------",data);
    return await axios.post(`/user/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createItem = async (data) => {
    console.log("createItem----------",data);
    return await axios.post(`/item/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createSlotsItem = async (data) => {
    console.log("createSlotsItem----------",data);
    return await axios.post(`/slots_items/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const deleteVM = async (id) => {
    return await axios.delete(`/vm/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}

export const deleteUser = async (id) => {
    return await axios.delete(`/user/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}

// export const publish_slot = async (slot_data) => {
//     return await axios.post(`/publish/slot/details/`, slot_data)
//     .then(res => {
//         console.log(res.data.data);
//         return res.data.data;
//     })
//     .catch(err => console.log(err.message));
// }

export const publish_vm_status_req = async (id) => {
    return await axios.post(`/publish/vm/details/req/${id}/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export default async function displayRazorpay(amount) {
    console.log("displayRazorpay", amount)
    const data = await axios.post("/razorpay", {
      amount: amount,
    }).then((res) => res.data);
  
    console.log("RAZORPAY+++++++++++",data);
   
    return data
  }

  export const uploadImageFile = async (formData) => {
    axios.post('/upload', formData)
      .then(response => {
        console.log("++++++FILE UPLOAD+++++++", response)
      })
      .catch(error => {
        console.error('There was an error uploading the file!', error);
        // resolve();
      });
  }

  export const deleteItem = async (id) => {
    return await axios.delete(`/item/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllVMSlotsItems = async (vm_id) => {
    return await axios.get(`/item/slots/${vm_id}`)
    .then(res => {
        console.log("++++++VM ASSIGNED SLOT ITEMS+++++",res);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createOrder = async (data) => {
    console.log("createOrder----------",data);
    return await axios.post(`/order/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createTransaction = async (data) => {
    console.log("createOrder----------",data);
    return await axios.post(`/transaction/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllOrders = async () => {
    return await axios.get(`/order/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllTransactions = async () => {
    return await axios.get(`/transaction/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const pushDispenseItemDetails = async (data) => {
    console.log("pushDispenseItemDetails----------",data);
    return await axios.post(`/publish/slot/details/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAnalytics = async (admin_id) => {
    console.log("analytics >>>>");
    return axios.get(`/analytics/${admin_id}/`)
    .then(res => {
        console.log({res});
        return res.data;
    })
    .catch(err => console.log(err.message));
}

export const getOrderDetailsByDate = async (startDate, endDate, selectedVM) => {
    console.log("getOrderDetailsByDate >>>>");
    return axios.get("/custom_order/", {
        params: {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
          vm: selectedVM, 
        }
      })
    .then(res => {
        console.log({res});
        return res.data;
    })
    .catch(err => console.log(err.message));
}