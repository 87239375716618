import React from "react";
import {Skeleton, Box} from "@mui/material";


export const TableRowsLoader = () => {
    return(
        <Box sx={{ width: 300 }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton width="60%" />
            <Skeleton animation={false} />
            <Skeleton variant="" width="100%" height={118} />
            <Skeleton width="60%" />
        </Box>
    ) 
};