import React, { useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';
import { Button, Box } from '@mui/material';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // This is important for accessibility

const QrCodeModal = ({ vm_id, isOpen, onRequestClose }) => {
  const qrRef = useRef();

  const downloadQrCode = () => {
    toPng(qrRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'qr-code.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('Failed to download QR code:', err);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} sx={{alignContent:'center', textAlign:'center', margin:'auto', mx:'auto'}}>
        <Box sx={{alignContent:'center', textAlign:'center', margin:'auto'}}>
            <h2>QR Code</h2>
            <div ref={qrRef}>
                <QRCode value={`https://ivm9.co.in/vm/${vm_id}`} size={256} level="H" includeMargin={true} />
            </div>
            <Button variant="contained" color="primary" onClick={downloadQrCode} style={{ marginTop: '20px' }} sx={{mr:1}}>
                Download QR Code
            </Button>
            <Button variant="contained" color="primary" onClick={onRequestClose} style={{ marginTop: '20px' }}>
                Close
            </Button>
        </Box>
    </Modal>
   
  );
};

export default QrCodeModal;
