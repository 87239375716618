import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    InputLabel,
    Select
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
// import { data, states } from './makeData';
import {getAllVM, updateVM, createVM, deleteVM, getAllUsers} from '../../../apis/routes'
import { TableRowsLoader } from '../../../utils/TableRowsLoader';

const VendingMachine = () => {

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState('');
  const [admins, setAdmins] = useState([])

  const updateVMResponseData = (data, users) =>{
    data.map((item, index) => {
      data[index]["status"] = item.status === 10 ? 'Online' : (item.status === 20? "Offline" :(item.status === 0? "New":"Not Working"));
      data[index]["created_at"] = (new Date(item.created_at)).toLocaleString();
      data[index]["updated_at"] = (new Date(item.updated_at)).toLocaleString();
      const admin_details = users.filter(item => data[index]["admin_assigned_id"] === item.guid)[0];
      data[index]["admin_name"] = admin_details.first_name+ " "+ admin_details.last_name;
    })
    return data;
  }

  const getAdminsFromUsers = (data) =>{
    return data.filter(item => item.is_admin)
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const users = await getAllUsers();
      setAdmins(getAdminsFromUsers(users));
      const response = await getAllVM();
      console.log({vms: response});
      setTableData(updateVMResponseData(response, users));
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    console.log({admins});
  }, []);

  const handleChange = (event) => {
      setAdmin(event.target.value);
  };

  const handleCreateNewRow = async (values) => {
    // values["items_dispensed"] = 0;
    // values["status"] = "New";
    // values["sales_amount"] = 0;
    // values["created_at"] = new Date().toLocaleString()
    // values["updated_at"] = new Date().toLocaleString()
    values["admin_assigned"] = admin;
    console.log("values>>>>>>>>", values)
    await createVM(values);
    setAdmin("");
    // tableData.push(updateVMResponseData([data]));
    await fetchData();
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      values["admin_assigned"] = admin;
      delete values["admin_name"];
      console.log({edited_values: values});
      const edit_payload = {number_of_slots: values.number_of_slots, name: values.name};
      if (values.admin_assigned) {
        edit_payload["admin_assigned"] = values.admin_assigned
      }
      await updateVM(values.guid, edit_payload);
      setAdmin("");
      fetchData();
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ?`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      console.log({row}, row["original"]["guid"]);
      tableData.splice(row.index, 1);
      await deleteVM(row["original"]["guid"]);
      await fetchData();
    },
    [tableData],
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        // error: !!validationErrors[cell.id],
        // helperText: validationErrors[cell.id],
        // onBlur: (event) => {
        //   const isValid = cell.column.id === 'item_price' ? isNaN(event.target.value) : true
        //   if (!isValid) {
        //     //set validation error for cell if invalid
        //     setValidationErrors({
        //       ...validationErrors,
        //       [cell.id]: `Price should be number`,
        //     });
        //   } else {
        //     //remove validation error for cell if valid
        //     delete validationErrors[cell.id];
        //     setValidationErrors({
        //       ...validationErrors,
        //     });
        //   }
        // },
      };
    },
    [validationErrors],
  );

  // const getMenuForAdmins = () => {
  //   console.log('getMenuForAdmins');
  //   return {
  //     select: true, //change to select for a dropdown
  //     children: admins && admins.map((admin) => (
  //       <MenuItem key={admin.guid} value={admin.first_name+ " " +admin.last_name}>
  //         {admin.first_name} {admin.last_name}
  //       </MenuItem>
  //     )),
  //   };
  // }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'guid',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 20,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 30,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        accessorKey: 'number_of_slots',
        header: 'Number of Slots',
        size: 30,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'sales_amount',
        header: 'Sales Amount',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
      {
        accessorKey: 'admin_name',
        header: 'Admin',
        muiTableBodyCellEditTextFieldProps: ({cell})=>({
          select: true, //change to select for a dropdown
          onChange: (event) => {setAdmin(event.target.value)},
          // defaultValue:cell.getValue(),
          // displayEmpty: true,
          // renderValue: cell.getValue(),
          children: admins && admins.map((admin) => (
            <MenuItem value={admin.guid}>
              {admin.first_name} {admin.last_name}
            </MenuItem>
          )),
        }),
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
      {
        accessorKey: 'updated_at',
        header: 'Updated At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
    ],
    [getCommonEditTextFieldProps, admins],
  );

  return (
    <>
      {loading? <TableRowsLoader /> : <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableColumnActions={false}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Vending Machine
          </Button>
        )}
      />}
      <CreateNewAccountModal
        columns={columns}
        admin={admin}
        admins={admins}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        onHandleChange={handleChange}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, admin, admins, onClose, onSubmit, onHandleChange }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Vending Machine</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              my:3,
            }}
          >
            {columns.map((column) => (
              (column.accessorKey === 'number_of_slots' || column.accessorKey === 'name') && <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select 
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={admin}
                label="User Type"
                name="user_type"
                onChange={onHandleChange}
            >
              {admins && admins.map((admin)=>{
                return <MenuItem value={admin.guid}>{admin.first_name} {admin.last_name}</MenuItem>
              })}
                
            </Select>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Create New Vending Machine
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
// const validateAge = (age) => age >= 18 && age <= 50;

export default VendingMachine;
