import React from 'react';
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Logout({color="error"}) {
    const navigate = useNavigate();
    const logout = () => {
        // localStorage.removeItem("authenticated");
        Cookies.remove('ivm9@user_token');
        navigate("/");
    }
    return <Button color={color} variant={color === "primary"?"contained":""} onClick={logout}>Logout</Button>
}