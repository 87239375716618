import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    Input,
    InputLabel,
    Select,
    Avatar,
    Tooltip
} from '@mui/material';
import { QRCodeCanvas } from "qrcode.react"


import QrCodeModal from '../../../utils/QR';

import { deepOrange, deepPurple } from '@mui/material/colors';
import { DataGrid, GridColDef, GridApi, GridCellValue  } from '@mui/x-data-grid';
import { Delete, Edit, DownloadIcon } from '@mui/icons-material';
// import { data, states } from './makeData';
import {getAllVM, getAllItems, createSlotsItem, getAllSlotsItems} from '../../../apis/routes'
import { TableRowsLoader } from '../../../utils/TableRowsLoader';
import { getUserDetails } from '../../../utils/utils';

const VendingMachine = () => {
    const curr_user = getUserDetails();
    console.log({ curr_user });
    const user_id = curr_user.guid
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [itemData, setItemData] = useState(() => []);
    const [selectedItem, setSelectedItem] = useState({});
    const [slots, setSlots] = useState(0);
    const [selectedVM, setSelectedVM] = useState("");
    const [qty, setQty] = useState({});
    const [slotItems, setSlotItems] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVM, setCurrentVM] = useState('')
    // const [admin, setAdmin] = useState('');
    // const [admins, setAdmins] = useState([])

    const updateVMResponseData = (data) =>{
        data.map((item, index) => {
            data[index]["id"] = item.guid;
            data[index]["status"] = item.status === 10 ? 'Online' : (item.status === 20? "Offline" :(item.status === 0? "New":"Not Working"));
            data[index]["created_at"] = (new Date(item.created_at)).toLocaleString();
            data[index]["updated_at"] = (new Date(item.updated_at)).toLocaleString();
            // const admin_details = users.filter(item => data[index]["admin_assigned_id"] === item.guid)[0];
            // data[index]["admin_name"] = admin_details.first_name+ " "+ admin_details.last_name;
        })
        return data.filter((item) => item.admin_assigned_id === user_id);
    }

    const getRelevantItemSlots = (slot_data, vms) => {
        const admin_vm_ids = vms.map(item => item.guid);
        console.log({ admin_vm_ids });
        const slot_mapping = {
            item_value: {},
            qty: {},
        }
        slot_data.forEach(item => {
            if (admin_vm_ids.includes(item.vending_machine_id_id)) {
                console.log("!!!!!!!!!!!!!!!", item);
                if(!slot_mapping["item_value"][item.vending_machine_id_id]) {
                    slot_mapping["item_value"][item.vending_machine_id_id] = {};
                    slot_mapping["qty"][item.vending_machine_id_id] = {};
                }
                slot_mapping["item_value"][item.vending_machine_id_id][item.slot_id] = item.item_id_id;
                slot_mapping["qty"][item.vending_machine_id_id][item.slot_id] = item.quantity;
            }
        });
        console.log({slot_mapping});
        return slot_mapping;
    }

    const getAdminsFromUsers = (data) =>{
        return data.filter(item => item.is_admin)
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            // const users = await getAllUsers();
            // setAdmins(getAdminsFromUsers(users));
            const [items, vms, slot_items] = await Promise.all([getAllItems().then((data)=>{
                return data.filter(i => i.metadata.user_id && i.metadata.user_id === user_id)
            }), getAllVM(), getAllSlotsItems()]);
            console.log({slot_items});
            const admin_vms = updateVMResponseData(vms);
            const admin_item_slots = getRelevantItemSlots(slot_items, admin_vms);
            setSlotItems(admin_item_slots);
            setItemData(items);
            setTableData(admin_vms);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        console.log("+++++++++++++++",{tableData});
    }, []);

    const onRowsSelectionHandler = (selectedVM) => {
        console.log("===============", {selectedVM});
        console.log("===============", {tableData});
        const vm = tableData.find(item => item.id === selectedVM.guid)
        setSelectedVM(vm.guid);
        const slots = vm.number_of_slots;
        console.log({slots});
        setSlots(slots);
        console.log("slotItems", slotItems)
        console.log(">+-----+++++", slotItems["item_value"][vm.guid], slotItems["qty"][vm.guid]);
        setSelectedItem(slotItems["item_value"][vm.guid]);
        setQty(slotItems["qty"][vm.guid]);
        setCreateModalOpen(true);
        
        // console.log(">>>>>>>>",{ids}, vms_for_dropdown);
        // const selectedRowsData = ids.map((id) => opData.find((row) => row.guid === id));
        
        // const selected_vm_ids = selectedRowsData[0].vm_ids;
        // const selected_vms = []
        
        // vms_for_dropdown.forEach(vm => {
        //     if (selected_vm_ids.includes(vm.guid)) {
        //         selected_vms.push(createData(vm.guid, vm.status, vm.sales_amount, vm.items_dispensed));
        //     }
        // });
        // console.log(">>>>>>>>",{selected_vms});
        // setRows(selected_vms);
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

    const handleChange = (event) => {
        const obj = event.target;
        console.log("##############", {obj})
        const key = parseInt(obj.name,10)+1;
        console.log("+++", selectedItem)
        setSelectedItem({ ...selectedItem, [key]: obj.value});
    };

    const handleQtyChange = (event, value) => {
        const obj = event.target;
        console.log("+++++", qty)
        setQty({ ...qty, [obj.name]:parseInt(obj.value, 10)});
    }

    const handleAssignItemsToVm = async () => {
        console.log({ selectedVM, selectedItem, qty });
        const payload = [];
        Object.keys(selectedItem).forEach(key => {
            payload.push({
                slot_id: key,
                item_id: selectedItem[key],
                vending_machine_id: selectedVM,
                quantity: qty[key],
            })
        });
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@", payload);
        await createSlotsItem(payload);
        setSelectedItem({});
        setQty({})
        await fetchData();
    };

    const getCommonEditTextFieldProps = useCallback(
    (cell) => {
        return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
        },
        };
    },
    [validationErrors],
    );

    const columns = [
        { field: 'id', headerName: 'ID', width: 350 },
        {
            field: 'name',
            headerName: 'VM Name',
            width: 100,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'number_of_slots',
            headerName: 'Slots',
            width: 100,
        },
        // {
        //     field: 'online_vm',
        //     headerName: 'Online Vending Machine',
        //     width: 180,
        // },
        // { field: 'status', headerName: 'Status', width: 100, type: 'number' },
        { field: 'sales_amount', headerName: 'Sales Amount', width: 100, },
        { field: 'items_dispensed', headerName: 'Items Dispensed', width: 130 },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                const click = (e) => {
                    const currentRow = params.row;
                    setCurrentVM(currentRow.guid)
                    handleOpenModal()
                };
        
              return <Button onClick={click}>Download QR</Button>;
            }
        },
        {
            field: "action1",
            headerName: "Action",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                const click = (e) => {
                    const currentRow = params.row;
                    onRowsSelectionHandler(currentRow)
                };
        
              return <Button onClick={click}>Assign Items</Button>;
            }
        },
    ];
    

    return (
        <>
            {!loading && <div style={{ height: 400, }}>
                        <DataGrid
                            rows={tableData}
                            columns={columns}
                            // initialState={{
                            // pagination: {
                            //     paginationModel: { page: 0, pageSize: 5 },
                            // },
                            // }}
                            pageSizeOptions={[5, 10]}
                            // checkboxSelection
                            // onRowSelectionModelChange={(newRow)=>onRowsSelectionHandler(newRow)}
                        />
                </div>
            }
            <QrCodeModal
                vm_id={currentVM}
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
            />
            <AssignItemtoVMModal            
            open={createModalOpen}
            slots = {slots}
            selectedItem = {selectedItem}
            items={itemData}
            onClose={() => setCreateModalOpen(false)}
            onSubmit={handleAssignItemsToVm}
            onHandleChange={handleChange}
            onHandleChangeQty={handleQtyChange}
            qty={qty}
            />
        </>
   
    );
};

//example of creating a mui dialog modal for creating new rows
export const AssignItemtoVMModal = ({ open, slots, selectedItem, items, onClose, onSubmit, onHandleChange, onHandleChangeQty, qty }) => {
    // const [values, setValues] = useState(() =>
    // eslint-disable-next-line no-undef
    // [...Array(slots).keys()].reduce((acc, index) => {
    //     acc[index] = "";
    //     return acc;
    // }, {}),
    // );

    const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
    };

    return (
    <Dialog open={open}>
        <DialogTitle textAlign="center">Assign Items to Slots</DialogTitle>
        <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
                my:3,
            }}
            >
            {[...Array(slots).keys()].map((key) =>
                <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>{key+1}</Avatar>

                    <Select 
                        // labelId="demo-simple-select-helper-label"
                        fullWidth
                        id="demo-simple-select-label"
                        value={!selectedItem || Object.keys(selectedItem).length === 0 ? '' : selectedItem[key+1]}//{Object.keys(selectedItem).length === 0 ? (items.find(item => item.guid === selectedItem[key+1])?`${key+1}:${selectedItem[key+1]}`:'') : selectedItem[key+1]}//{items.find(item => item.guid === selectedItem[key+1])?.item_name}
                        label="Item"
                        name={key}
                        onChange={onHandleChange}
                    >
                        {items.map((item)=>{
                        return <MenuItem value={item.guid}>{item.item_name}</MenuItem>
                        })}
                    </Select>
                    <Input
                        placeholder="Enter quantity"
                        name={key+1}
                        value={!qty || Object.keys(qty).length === 0 ? 0 : qty[key+1]}
                        onChange={onHandleChangeQty}
                        type="number"
                    />

                </Stack>
                )
            }
            </Stack>
        </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
            Assign Items to Slots
        </Button>
        </DialogActions>
    </Dialog>
    );
    };

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
!!email.length &&
email
.toLowerCase()
.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
// const validateAge = (age) => age >= 18 && age <= 50;

export default VendingMachine;
