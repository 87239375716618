import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button'
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


import {getAnalytics, getAllVM, getOrderDetailsByDate} from '../../../apis/routes'
import { getUserDetails } from '../../../utils/utils';

export default function Sales(){
    const curr_user = getUserDetails();
    console.log({ curr_user });
    const user_id = curr_user.guid
    const [analyticsData, setAnalyticsData] = useState({})
    const [customAnalyticsData, setCustomAnalyticsData] = useState({})
    const [selectedVM, setSelectedVM] = useState("")
    const [startDate, setStartDate] = useState(dayjs('2024-04-17'))
    const [endDate, setEndDate] = useState(dayjs('2024-04-17'))
    const [loading, setLoading] = useState(true);
    const [vms, setVMs] = useState([]);

    const fetchData = async () => {
      try {
          setLoading(true);
          // const users = await getAllUsers();
          // setAdmins(getAdminsFromUsers(users));
          // const [items, vms, slot_items] = await Promise.all([getAllItems().then((data)=>{
          //     return data.filter(i => i.metadata.user_id && i.metadata.user_id === user_id)
          // }), getAllVM(), getAllSlotsItems()]);
          // console.log({slot_items});
          const analytics = await getAnalytics(user_id);
          console.log("++++++++", {analytics}, analyticsData);
          setAnalyticsData(analytics);
          setVMs((await getAllVM()).filter(item => item.admin_assigned_id === user_id))
          // const admin_item_slots = getRelevantItemSlots(slot_items, admin_vms);
          // setSlotItems(admin_item_slots);
          // setItemData(items);
          // setTableData(admin_vms);
          setLoading(false);
      } catch (error) {
          console.log("error", error);
          setLoading(false);
      }
    }

    const getOrderDetails = async() => {
      setLoading(true);
      const data = await getOrderDetailsByDate(startDate, endDate, selectedVM);
      setCustomAnalyticsData(data);
      setLoading(false);
    } 

    useEffect(() => {
        fetchData();
        // console.log("+++++++++++++++",{tableData});
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
          backgroundColor: '#1A2027',
        }),
        fontSize: "20px", 
        fontWeight: "bold",
        height: "120px",
      }));
    return (
      !loading &&  <><Box
          sx={{
            display: 'flex',
            fle1xWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
            },
          }}
        >
          {/* <Paper elevation={3} ></ Paper>
          <Paper elevation={3} ></ Paper>
          <Paper elevation={3} ></ Paper> */}
          <Grid container spacing={2} >
            <Grid item xs={6} md={4}>
                <Item><CurrencyExchangeIcon sx={{mt: 4, mr:2}}/>Life time Sale: <br/>Rs.<i>{analyticsData.lifetime_sell_amount}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><FeaturedPlayListIcon sx={{mt: 2, mr:2}}/>Life time Sold Items: <br/><i>{analyticsData.lifetime_item_count}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><ShoppingBasketIcon sx={{mt: 2, mr:2}}/>Life time Purchases: <br/><i>{analyticsData.lifetime_purchase}</i></Item>
            </Grid>
          {/* </Grid> */}
          {/* <Grid container spacing={2} > */}
            <Grid item xs={6} md={4}>
                <Item><CurrencyExchangeIcon sx={{mt: 4, mr:2}}/>Last 7 days Sale: <br/>Rs.<i>{analyticsData.last_7_days_sell_amount}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><FeaturedPlayListIcon sx={{mt: 2, mr:2}}/>Last 7 days Sold Items: <br/><i>{analyticsData.last_7_days_item_count}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><ShoppingBasketIcon sx={{mt: 2, mr:2}}/>Last 7 days Purchases: <br/><i>{analyticsData.last_7_days_purchase}</i></Item>
            </Grid>

            <Grid item xs={6} md={4}>
                <Item><CurrencyExchangeIcon sx={{mt: 4, mr:2}}/>Last 30 days Sale: <br/>Rs.<i>{analyticsData.last_30_days_sell_amount}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><FeaturedPlayListIcon sx={{mt: 2, mr:2}}/>Last 30 days Sold Items: <br/><i>{analyticsData.last_30_days_item_count}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><ShoppingBasketIcon sx={{mt: 2, mr:2}}/>Last 30 days Purchases: <br/><i>{analyticsData.last_30_days_purchase}</i></Item>
            </Grid>

            <Grid item xs={6} md={4}>
                <Item><CurrencyExchangeIcon sx={{mt: 4, mr:2}}/>Last 365 days Sale: <br/>Rs.<i>{analyticsData.last_365_days_sell_amount}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><FeaturedPlayListIcon sx={{mt: 2, mr:2}}/>Last 365 days Sold Items: <br/><i>{analyticsData.last_365_days_item_count}</i></Item>
            </Grid>
            <Grid item xs={6} md={4}>
                <Item><ShoppingBasketIcon sx={{mt: 2, mr:2}}/>Last 365 days Purchases: <br/><i>{analyticsData.last_365_days_purchase}</i></Item>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ justifyContent:"center", alignContent:"center", marginTop: 5}}>
          <Paper elevation={3} sx={{ justifyContent:"center", textAlign:"center", marginBottom:5, fontSize:"20px", fontWeight:"bold"}} >Machine Specific Lifetime Data</Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{fontSize:"15px", fontWeight:"bold"}}>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}}>Vending Machine</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Life Time Sale Amount</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Life Time Item Sale Count</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Life Time Purchases</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analyticsData.lifetime.map((row) => (
                  <TableRow
                    key={row.vending_machine_id_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.vending_machine_id_id}
                    </TableCell>
                    <TableCell align="right">{row.total_amount}</TableCell>
                    <TableCell align="right">{row.total_items}</TableCell>
                    <TableCell align="right">{row.number_of_purchases}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ justifyContent:"center", alignContent:"center", marginTop: 5}}>
          <Paper elevation={3} sx={{ justifyContent:"center", textAlign:"center", marginBottom:5, fontSize:"20px", fontWeight:"bold"}} >Machine Specific Custom Data</Paper><br/>
          <Box sx={{ marginLeft:15 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker mr={3} sx={{marginRight:3}} label="Select start date" defaultValue={startDate} onChange={(newValue) => setStartDate(newValue)} />
              <DatePicker mr={2} label="Select end date" defaultValue={endDate} onChange={(newValue) => setEndDate(newValue)}/>
            </LocalizationProvider>
            <FormControl sx={{width: "25%", marginLeft: 3, marginRight:3}}>
              <InputLabel id="select-label">Select Machine</InputLabel>
              <Select
                labelId="select-label"
                id="vm-select"
                value={selectedVM}
                label="Select Machine"
                onChange={(event) => setSelectedVM(event.target.value)}
              >
                {vms.map((item) => {
                  return <MenuItem value={item.guid}>{item.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Button variant="contained" sx={{marginTop: 1}} onClick={getOrderDetails}>Get Order Details</Button>
          </Box>
          {!loading && customAnalyticsData && Object.keys(customAnalyticsData).length > 0 && 
            <Box mt={5}>
              <Grid container spacing={2} sx={{marginBottom: 2}}>
                <Grid item xs={6} md={4}>
                    <Item><CurrencyExchangeIcon sx={{mt: 4, mr:2}}/>Custom time Sale: <br/>Rs.<i>{customAnalyticsData.total_sales_amount}</i></Item>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Item><FeaturedPlayListIcon sx={{mt: 2, mr:2}}/>Custom time Sold Items: <br/><i>{customAnalyticsData.total_items_sold}</i></Item>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Item><ShoppingBasketIcon sx={{mt: 2, mr:2}}/>Custom time Purchases: <br/><i>{customAnalyticsData.purchase_count}</i></Item>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{fontSize:"15px", fontWeight:"bold"}}>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}}>Order Id</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Sale Amount</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Item Count</TableCell>
                  <TableCell sx={{fontSize:"15px", fontWeight:"bold"}} align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customAnalyticsData.orders.map((row) => (
                  <TableRow
                    key={row.guid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.guid}
                    </TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.item_count}</TableCell>
                    <TableCell align="right">{row.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            </Box>
          }
        </Box>
      </>
      );
}
