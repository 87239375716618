import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

// import jwt from 'jsonwebtoken';

export const getUserDetails = () => {
    const token = Cookies.get('ivm9@user_token');
    if (!token) return null;
    console.log("+++++++++.", {token})
    try {
        const decoded = jwtDecode(token);
        console.log({decoded})
        return decoded;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export function isLoggedIn() {
    const user = getUserDetails();
    console.log(">>>  ", {user}, )
    if (user) return true;
    return false;
}