import React from "react";
import Layout from "./../components/Layout/Layout";
import Location from "@mui/icons-material/PinDrop";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const Contact = () => {
  return (
    <Layout>
      <Box sx={{ textAlign: "center", my: 5, mx: 5, "& h4": { fontWeight: "bold", mb: 2 } }}>
        <Typography variant="h4">Contact to resolve your queries</Typography>
        <p>
        </p>
      </Box>
      <Box
        alignItems="center"
        sx={{
            display:"flex",
            textAlign: "center",
            m: 3,
            mx: 15,
            "@media (max-width:600px)": {
            mx: 1,
            },
            justifyContent: 'center'
        }}

      >
        <TableContainer component={Paper}>
          <Table aria-label="contact table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ bgcolor: "#2196f3", color: "white", fontWeight: "bold", fontSize:"18px" }}
                  align="center"
                >
                  Contact Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="MuiTableCell-alignCenter">
                    <Stack sx={{margin:'auto', textAlign:'center', justifyContent:'center'}} direction="row" spacing={2}>
                        <Location fontSize="large" sx={{ color: "red",}} /> 
                        <Typography>plot no: 83, behind ICAR NRRI campus, Bhadimul, Cuttack, Odisha, 753006</Typography>
                    </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                    <Stack sx={{margin:'auto', textAlign:'center', justifyContent:'center'}} direction="row" spacing={2}>
                        <MailIcon fontSize="medium" sx={{ color: "#2196f3",}} /> 
                        <Typography>ezibees8@gmail.com</Typography>
                    </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                    <Stack sx={{margin:'auto', textAlign:'center', justifyContent:'center'}} direction="row" spacing={2}>
                        <CallIcon fontSize="medium" sx={{ color: "green",}} /> 
                        <Typography>7008000500 / 9040001204</Typography>
                    </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default Contact;